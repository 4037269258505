import React, { useEffect, useState } from 'react';
import Carousel from 'nuka-carousel';

import { FiveStars } from '../../utility/Icons';
import { reviews } from '../../../lib/constants';
import { isMobile, isTablet } from '../../../lib/getDeviceSize';

const DownArrowIcon = (props: any) => {
  return (
    <svg
      width="42"
      height="29"
      viewBox="0 0 42 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.1311 24.7977C25.0532 30.1377 17.3468 30.1377 14.2689 24.7977L0.400005 0.735897L42 0.735901L28.1311 24.7977Z"
        fill="#463264"
      />
    </svg>
  );
};

export interface State {
  reviews: any;
  width: number;
}
const ReviewsLength = reviews.length;
interface Props {
  title: string;
  autoSlide: boolean;
  dontShowButtons?: boolean;
}

export const Reviews: React.FC<Props> = ({
  autoSlide,
  title,
  dontShowButtons,
}) => {
  const [width, setWidth] = useState<number>(0);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    // EventEmitter.on('slideReview', handleReviewSlide);
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      // EventEmitter.removeListener('slideReview', handleReviewSlide);
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const handleReviewSlide = (next: boolean) => {
    if (next) {
      if (index === ReviewsLength - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    } else {
      if (index === 0) {
        setIndex(ReviewsLength - 1);
      } else {
        setIndex(index - 1);
      }
    }
  };

  const boughtList = () => {
    return reviews.map((item: any, index: number) => (
      <div className="box testimonial-item" key={index}>
        <div className="review-box">
          <FiveStars small />
          <p className="quote">{item.comment}</p>
          <p className="info">{item.name}</p>
        </div>
        <DownArrowIcon style={{ marginTop: -10 }} />
        <div>
          <img className="quote-avatar" alt="Reviewer photo" src={item.img} />
        </div>
      </div>
    ));
  };

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  const desktopSmall = width <= 1280 && width > 949;
  const tablet = width <= 949 && width > 529;
  const mobile = width <= 529;

  let slidesToShow = 4.2;
  let containerWidth: any = '100%';

  if (desktopSmall) {
    slidesToShow = 3;
  }
  if (tablet) {
    slidesToShow = 2;
  } else if (mobile) {
    slidesToShow = 1;
  }

  const renderReviewNavigationButton = (center: boolean) => {
    return (
      <div
        className="navigationButtonContainer"
        style={{ margin: center ? '10px 0px' : '' }}
      >
        <button
          className="navigationButton"
          onClick={() => handleReviewSlide(false)}
        >
          <img src="/static/img/leftArrowButton.png" alt="arrow-left" />
        </button>
        <button
          className="navigationButton"
          onClick={() => handleReviewSlide(true)}
        >
          <img src="/static/img/rightArrowButton.png" alt="arrow-right" />
        </button>
      </div>
    );
  };

  return (
    <div className="row center-xs between-xs">
      <div
        style={{
          margin: '0px auto',
          width: '100%',
          maxWidth: containerWidth,
          marginBottom: 0,
        }}
      >
        <div className="reviews-header-container">
          <h2>{title}</h2>
          {!isMobile() &&
            !isTablet() &&
            !dontShowButtons &&
            renderReviewNavigationButton(false)}
        </div>
        <Carousel
          cellAlign="center"
          autoplay={autoSlide}
          autoplayInterval={mobile ? 6000 : 4000}
          cellSpacing={50}
          dragging={false}
          easing="easePolyOut"
          pauseOnHover={false}
          slideIndex={index}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          speed={1500}
          swiping={false}
          withoutControls
          wrapAround
        >
          {boughtList()}
        </Carousel>
        {(isMobile() || isTablet()) &&
          !dontShowButtons &&
          renderReviewNavigationButton(true)}
      </div>
    </div>
  );
};

Reviews.defaultProps = {
  autoSlide: true,
};
export default Reviews;
