import StyleBase from './base';

const styles = {
  homeBg: {
    [StyleBase.media.mobile]: {
      height: 802,
    },
    [StyleBase.media.tablet]: {
      height: 972,
    },
    [StyleBase.media.desktopXl]: {
      height: 640,
    },
    height: 640,
    padding: 0,
  },
  reviewLabel: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 2.3,
    textDecoration: 'none',
  },
  socialLabel: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 2.25,
    textDecoration: 'none',
  },
};

export default styles;
