import * as React from 'react';
import { withRouter } from 'next/router';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { connect } from 'react-redux';

const AlertDialog = dynamic(() => import('./AlertDialog'));
const ForgotDialog = dynamic(() => import('./ForgotDialog'));
const LoginDialog = dynamic(() => import('./LoginDialog'));
const SignupDialog = dynamic(() => import('./SignupDialog'));
const SuccessDialog = dynamic(() => import('./SuccessDialog'));
const WelcomeDialog = dynamic(() => import('./WelcomeDialog'));
const HelpDialog = dynamic(() => import('./HelpDialog'));
const WelcomeSurveyDialog = dynamic(() => import('./WelcomeSurveyDialog'));
const ShowingRequest = dynamic(() =>
  import('../../components/utility/ShowingScheduler'),
);
const MobileDialog = dynamic(() =>
  import('../../components/utility/OfferModal'),
);
const SnackBarNotification = dynamic(() =>
  import('../utility/SnackBarComponent'),
);
const SaveAndContinueDialog = dynamic(() => import('./SaveAndContinueDialog'));
const SaveAndContinueSuccessDialog = dynamic(() =>
  import('./SaveAndContinueSuccessDialog'),
);
const FavoriteSuccessDialog = dynamic(() => import('./FavoritesSuccessDialog'));

import { formatUrlAddress } from '../../lib/helpers';
import { HEADER_HIDDEN_ROUTES } from '.';

export interface Props {
  children: any;
  disableOverflow?: boolean;
  dispatch: any;
  hideHeader?: boolean;
  hideFooter?: boolean;
  listing?: any;
  overrideImage?: string;
  overrideRemarks?: string;
  router: any;
  scheduleShowingDialogOpen: boolean;
  scheduleShowingListing: any;
  title?: string;
  token: string;
}

export interface State {
  height: number;
}

const commonStyles = {
  backgroundColor: '#fff',
};

export class PageBase extends React.Component<Props, State> {
  state = {
    height: 500,
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight,
    });
  };

  render() {
    const {
      children,
      disableOverflow,
      listing,
      overrideImage,
      overrideRemarks,
      scheduleShowingDialogOpen,
      scheduleShowingListing,
      title,
      router,
      token,
    } = this.props;
    let imageContent = 'https://www.toriihomes.com/static/img/home/phone.png';
    const baseUrl = 'https://www.toriihomes.com';
    let url = `${baseUrl}${this.props.router.path || ''}`;
    let remarks = `Personalized home buying that will blow your mind.`;
    if (listing) {
      imageContent = listing.photoMedium;
      url = `${baseUrl}/listing/r/${listing.id}/${formatUrlAddress(listing)}`;
      remarks = listing.remarks;
    }
    const titleToUse = title ? title : `Torii Homes`;
    const remarksToUse = overrideRemarks ? overrideRemarks : remarks;
    const imageToUse = overrideImage ? overrideImage : imageContent;

    const pathName = router.pathname;
    const hideLayout = HEADER_HIDDEN_ROUTES.indexOf(pathName) >= 0;
    const HEADER_HEIGHT = hideLayout ? 0 : 70;
    const contentHeight = this.state.height - HEADER_HEIGHT;
    return (
      <React.Fragment>
        <Head>
          <title key="title">{titleToUse}</title>
          <meta property="og:locale" content="en_US" />
          <meta name="twitter:title" content={titleToUse} />
          <meta name="twitter:image" content={imageToUse} />
          <meta property="fb:app_id" content="1908869376024982" />
          <meta property="og:image" content={imageToUse} />
          <meta property="og:image:secure_url" content={imageToUse} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="300" />
          <meta property="og:image:alt" content="Property Image" />
          <meta property="og:site_name" content="toriihomes.com" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={titleToUse} />
          <meta property="og:url" content={url} />
          <meta property="og:description" content={remarksToUse} />
          <link rel="canonical" href={url} />
        </Head>
        <div
          style={
            disableOverflow
              ? { ...commonStyles, height: 'auto' }
              : {
                  ...commonStyles,
                  height: contentHeight,
                  width: '100%',
                  overflow: 'scroll',
                }
          }
        >
          {children}
        </div>
        {!token && (
          <div>
            <ForgotDialog />
            <LoginDialog />
            <SignupDialog />
          </div>
        )}
        {token && (
          <React.Fragment>
            <FavoriteSuccessDialog />
            <WelcomeDialog />
            <WelcomeSurveyDialog />
          </React.Fragment>
        )}
        <HelpDialog />
        <AlertDialog />
        <SuccessDialog />
        <SnackBarNotification />
        {scheduleShowingDialogOpen && (
          <ShowingRequest open={true} listing={scheduleShowingListing} />
        )}
        <MobileDialog />
        <SaveAndContinueDialog />
        <SaveAndContinueSuccessDialog />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  scheduleShowingDialogOpen: state.dialogs.scheduleShowingDialogOpen,
  scheduleShowingListing: state.dialogs.scheduleShowingListing,
  token: state.auth.token,
});

export default connect(mapStateToProps)(withRouter(PageBase));
